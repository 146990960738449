// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agathon-js": () => import("./../src/pages/agathon.js" /* webpackChunkName: "component---src-pages-agathon-js" */),
  "component---src-pages-georg-fischer-js": () => import("./../src/pages/georg-fischer.js" /* webpackChunkName: "component---src-pages-georg-fischer-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-metrohm-js": () => import("./../src/pages/metrohm.js" /* webpackChunkName: "component---src-pages-metrohm-js" */),
  "component---src-pages-quartzteq-js": () => import("./../src/pages/quartzteq.js" /* webpackChunkName: "component---src-pages-quartzteq-js" */),
  "component---src-pages-tempo-js": () => import("./../src/pages/tempo.js" /* webpackChunkName: "component---src-pages-tempo-js" */),
  "component---src-pages-u-blox-js": () => import("./../src/pages/u-blox.js" /* webpackChunkName: "component---src-pages-u-blox-js" */)
}

